.product-form-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  /* background-color: #f5f5f5; */
  padding: 1.25rem;
  border-radius: 0.5rem;
  height: 100%;
}

.header {
  color: #333;
  font-weight: 100 !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  margin-left: 5px !important;
}

.card {
  border-radius: 0.5rem;
  margin-bottom: 1.25rem;
}

.textField {
  margin-top: 5px;
  border-radius: 5px !important;
  margin-bottom: 15px;
}
.textfield .MuiOutlinedInput-root {
  border-radius: 5px;
}

.uploadButton {
  margin-top: 5px;
  width: 100%;
  border-radius: 25px;
  background: #b3b31c;
}
.uploadButton:hover {
  background: #316004;
}

.imagePreview {
  height: 300px;
  width: 300px;
  border-radius: 8px;
}

.saveButton {
  background: #5542f6;
  border-radius: 5px;
  width: 100%;
}

.saveButton:hover {
  background: #5542f6;
}

.saveButton .MuiOutlinedInput-root {
  border-radius: 15px;
}

.cancelButton {
  border-radius: 5px;
  background-color: white;
  color: #2c3e50;
  width: 100%;
}
.cancelButton:hover {
  background-color: rgb(234, 234, 234);
  color: black;
}
.cancelButton .MuiOutlinedInput-root {
  border-radius: 15px;
}
.layout {
  display: flex;
}
.container {
  width: 1000px;
  margin-left: 20px;
}

.container.expanded {
  width: calc(100% - 210px);
}

.container.collapsed {
  width: calc(100% - 64px);
}
