.generic-list-container {
  max-width: 100%;
  /* background-color: #f5f5f5; */
  padding: 2rem;
  border-radius: 0.5rem;
  height: 100%;
}

.header-box {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: nowrap;
}

.export-button,
.add-button {
  width: 120px !important;
  border-radius: 10px !important;
  padding: 10px;
  background: #5542f6;
  margin-right: 5px;
}
@media (max-width: 768px) {
  .add-button,
  .export-button {
    font-size: 12px !important; /* Smaller font size */
    padding: 8px 16px !important; /* Smaller padding */
  }
}

.export-button:hover {
  background-color: #5542f6 !important;
}

.add-button {
  /* background: linear-gradient(to right, #c0c016, #2b6005); */
  background: #5542f6 !important;
  color: #fff;
  margin-right: 10px;
}

.add-button:hover {
  background-color: #5542f6;
}

.header-title {
  color: black;
  font-weight: bold;
  margin-top: 10px !important;
  /* margin-left: 5px !important; */
}
.filter-box {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: flex-end;
  margin-top: 2px !important;
}
.total-container {
  padding: 8px;
  background-color: #eff3fe; /* Light gray background */
  border: 1px solid #eff3fe; /* Match the styling of other elements */
  border-radius: 5px;
  font-size: 0.9rem;
}

.search-input {
  border-radius: 5px;
  background-color: white;
  border-color: #eff3fe !important;
  border-width: 1px;
  padding: 10px;
  width: 200px;
  outline: none;
  margin-right: 10px;
}

.search-input:focus {
  border: 1px solid #eff3fe; /* Highlight border on focus */
}

.sort-select-container {
  display: flex;
}

.sort-select {
  width: 200px;
  border-radius: 5px;
  background-color: white;
  border-color: #eff3fe;
  padding: 10px;
  outline: none;
  margin-right: 10px;
}

.sort-select:focus {
  border: 1px solid #eff3fe; /* Highlight border on focus */
}
.table-container {
  /* margin-top: 0px !important; */
  margin-right: 1.5rem;
  /* padding-top: 0px; */

  overflow: hidden; /* Ensures content stays within the curved edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for a better look */
  border-radius: 12px !important;
  margin-bottom: 10px;
}

.table-container th,
.table-container td {
  /* border: none; */
  border-bottom: 1px solid #ddd;
}

.table-head {
  background-color: #eff3fe !important;
  /* border: none !important; */
}

.table-cell-header {
  text-align: center;
  background-color: #eff3fe;
  border: none !important;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.edit-button,
.delete-button {
  padding: 4px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.edit-button {
  background-color: rgba(0, 0, 255, 0.1);
}

.delete-button {
  background-color: rgba(255, 0, 0, 0.1);
}
.layout {
  display: flex;
  background-color: #f0f5f4;
  height: 2000 !important;
}
.mh-container {
  width: 1000px;
  margin-left: 20px;
}

.mh-container.expanded {
  width: calc(100% - 210px);
}

.mh-container.collapsed {
  width: calc(100% - 64px);
}

/* count card styling */

.stats-cards {
  margin-top: 20px;
}

/* Card Styles */
.total-products-card,
.total-generic-products-card {
  background-color: #eff3fe !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px !important;
}

/* Card Content */
.card-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 16px;
}

/* Card Icon */
.card-icon {
  font-size: 2.5rem !important;
  color: gray;
}
.product-icon {
  font-size: 2.5rem !important;
}

/* Card Number */
.card-number {
  color: #006aff !important;
  font-weight: bold;
}
.product-number {
  color: #4caf50;
  font-weight: bold;
}
