.generic-details-container {
  max-width: 100%;
  padding: 2rem;
  border-radius: 0.5rem;
}

.header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.header-text {
  color: black;
  font-weight: bold;
  padding-top: 15px;
  padding-left: 15px;
}

.table-container {
  width: 90%;
  border-radius: 25px;
  margin-top: 1.5rem;
}
/* .table {
  margin-left: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
} */

.table-head {
  background-color: #f5f5f5;
}

.table-cell-header {
  font-weight: bold !important;
  font-size: 15px;
}

.table-cell-key {
  font-weight: bold;
}
.layout {
  display: flex;
}
.mh-container {
  width: 1000px;
  margin-left: 20px;
}

.mh-container.expanded {
  width: calc(100% - 210px);
}

.mh-container.collapsed {
  width: calc(100% - 64px);
}
.custom-card {
  margin-bottom: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.nav-menu {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  gap: 50px;
  font-size: 15px;
  font-weight: 100;
}
.nav-menu li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  cursor: pointer;
  font-weight: 600;
}
.nav-menu hr {
  background: #5542f6;
  border: none;
  width: 80%;
  height: 2px;
  border-radius: 10px;
}
.details-card {
  background-color: #eff3fe !important;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
}
.generic-details {
  display: flex; /* Use flexbox for layout */
  flex-direction: row; /* Arrange items in a row */
  gap: 10px; /* Space between cards */
  justify-content: space-between; /* Optional: Adjust spacing between cards */
  align-items: flex-start; /* Optional: Align items at the top */
}

.card {
  flex: 1; /* Each card takes up equal width */
  padding: 10px;
}
.action-card {
  margin-top: 70px;
}
.custom-card::-webkit-scrollbar {
  height: 8px;
}

.custom-card::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.custom-card::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
