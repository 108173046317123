body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

html {
  font-size: 14px;
}

body {
  font-size: 1rem;
}

h1 {
  text-align: center;
  color: #333;
  margin-top: 20px;
}

.container {
  width: 80%;
  margin: 0 auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table,
th,
td {
  border: 1px solid #ddd;
}

th,
td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

input[type="text"],
select,
button {
  padding: 10px;
  margin: 10px 0;
  width: calc(100% - 22px);
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

form {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.actions button {
  margin-right: 5px;
}

h1 {
  align-items: center;
  justify-content: center;
}
.content {
  transition: margin-left 0.3s;
}

.content.collapsed {
  margin-left: 64px; /* Width of the collapsed sidebar */
}

.content.expanded {
  margin-left: 240px; /* Width of the expanded sidebar */
}
