.view-products-container {
  max-width: 100%;
  /* background-color: #f5f5f5; */
  padding: 2rem;
  border-radius: 0.5rem;
  height: 100%;
}
.header-box {
  margin-left: 10px;
  margin-top: 10px;
}
.table-container {
  border-radius: 12px !important;
}
.export-button,
.add-button {
  width: 120px !important;
  border-radius: 5px !important;
  padding: 10px 20px;
  margin-right: 10px;
}

.export-button {
  background-color: lightorange;
  color: #fff;
}

.export-button:hover {
  background: #316004;
}

.add-button {
  background: linear-gradient(to right, #c0c016, #2b6005);
  color: #fff;
}

.add-button:hover {
  background: #316004;
}

.table-head {
  background-color: #eff3fe !important;
  /* border: none !important; */
}

.table-cell-header {
  font-weight: bold;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.save-icon-button,
.cancel-icon-button,
.edit-icon-button {
  padding: 4px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.save-icon-button {
  background-color: rgba(0, 255, 0, 0.1);
}

.cancel-icon-button {
  background-color: rgba(255, 0, 0, 0.1);
}

.edit-icon-button {
  background-color: rgba(0, 0, 255, 0.1);
}
.layout {
  display: flex;
}
.mh-container {
  width: 1000px;
  margin-left: 20px;
}

.mh-container.expanded {
  width: calc(100% - 210px);
}

.mh-container.collapsed {
  width: calc(100% - 64px);
}
.total-container {
  padding: 8px;
  background-color: #eff3fe; /* Light gray background */

  border-radius: 5px;
  font-size: 0.9rem;
  margin-top: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
}
