.Sidebar {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 20px;
  height: "100%" !important;
  border-radius: 0 5px 5px 0;
}
.Sidebar.no-scroll {
  overflow: hidden !important; /* Extra class to ensure no scrolling */
}
.Sidebar__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  span {
    display: inline-block;
    cursor: pointer;
  }
}

.Sidebar__topNotExpanded {
  justify-content: center;
  overflow: hidden;
}

.Sidebar__image {
  width: 135px;
  height: 45px;
  margin-top: 15px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
}
.Sidebar__expand {
  cursor: pointer;
  /* margin-left: 5px; */
  margin-right: 10px;
  margin-top: 10px;
}

.Sidebar__expand:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.icons {
  display: flex;
  margin: 5px;
  color: #4a4a4a;
  padding: 10px;
  font-size: 12px;
  background-color: transparent;
  outline: none;
  border: none;
  align-items: center;
  margin-bottom: 0.8rem;
  font-weight: 600;
  border-radius: 4px;
  margin-left: 10px;
  margin-top: 10px;

  /* &:hover {
    background-color: #ececec;
    color: #0e2c24;
  } */
}
.icons img {
  filter: #4a4a4a;
}

a {
  display: flex;
}
span {
  margin-left: 10px;
  margin-top: 5px;
}

.altimg {
  padding: 5px;
  margin-bottom: 20px;
  padding-right: 5px;
}
.altimg:hover {
  border-radius: 100px;
}

.Sidebar__itemActive {
  background-color: #eff3fe;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  /* padding-right: 5px; */
  border-radius: 5px;
}

.Sidebar__itemActive a {
  margin-left: 3px;
  color: #5542f6;
}
/* .Sidebar__itemActive img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%)
    hue-rotate(342deg) brightness(97%) contrast(105%);
} */
