.generic-form-container {
  /* background-color: green; */
  padding: 1.25rem;
  border-radius: 0.5rem;
  height: 100%;
}
.generic-form-select .MuiOutlinedInput-root {
  border-radius: 20px;
}

.generic-form-textfield .MuiOutlinedInput-root {
  border-radius: 5px;
}
.generic-form-label {
  font-size: 15px;
  margin-right: 5px;
  color: black;
  font-weight: 200;
}
label {
  padding: 3px;
  display: inline-block;
  margin-bottom: 8px;
  font-weight: bold;
  color: black;
  font-weight: 200;
}

.generic-form-header {
  color: #333;
  font-weight: bolder;
  /* text-align: left; */
  margin-bottom: 10px !important;
  margin-top: 20px !important;
  margin-left: 50px;
}

.generic-form-card {
  border-radius: 0.5rem;
  margin-bottom: 50px;
  margin-top: 25px;
}

.generic-form-textfield,
.generic-form-select {
  margin-top: 5px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.generic-form-file-input {
  background-color: white;
  margin-top: 20px;
}

.save-button {
  /* background: linear-gradient(to right, #c0c016, #2b6005);
   */
  background-color: #5542f6;
  border-radius: 5px;
  padding: 10px !important;
  width: 100%;

  /* margin-left: 20px; */
}

.save-button:hover {
  background: #5542f6;
}

.save-button .MuiOutlinedInput-root {
  border-radius: 15px;
}

.cancel-button {
  border-radius: 5px;
  background-color: white;
  color: #2c3e50;
  padding: 10px !important;
  width: 100%;
}
.cancel-button:hover {
  background: rgb(234, 234, 234);
  color: black;
}
.cancel-button .MuiOutlinedInput-root {
  border-radius: 15px;
}
.add-btn {
  margin-top: 5px;
  width: 200px;
  margin-left: 30px;
  border-radius: 5px;
  background: #b3b31c;
}
.add-btn:hover {
  background-color: #316004;
}
.layout {
  display: flex;
}
.form-layout {
  display: flex;
  /* background-color: #ffd7cc; */
  background-color: #f0f5f4;
}

.container {
  width: 1000px;
  margin-left: 20px;
}

.container.expanded {
  width: calc(100% - 210px);
}

.container.collapsed {
  width: calc(100% - 64px);
}

/* .use-action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 25px;
}

.use {
  width: 650px;
  margin-left: 15px;
}
.action {
  width: 650px;
  margin-left: 20px;
}
.warning-reactions {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 25px;
}

.reactions {
  width: 650px;
  margin-left: 20px;
}

.warning {
  width: 650px;
  margin-left: 15px;
}

.cautions-availability {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 25px;
}
.cautions {
  width: 650px;
  margin-left: 15px;
}
.availability {
  width: 650px;
  margin-left: 20px;
}

.drug-comment {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 25px;
}
.drugs {
  width: 650px;
}*/
.comments {
  margin-top: 15px;
}
.save-cancel {
  margin-top: 50px;
}

.hierarchy-button {
  background: green;
}
