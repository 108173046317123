.container {
  max-width: 100%;
  /* background-color: #f5f5f5; */
  padding: 20px;
  border-radius: 10px;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  color: black;
  font-weight: bold;
}

.addButton {
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: #fff;
  width: 180px;
}

.addButton:hover {
  background-color: #1a252f;
}

.searchField {
  width: 200px;
  border-radius: 25px;
  background-color: #fff;
  padding: 10px;
}
.sort-select-container {
  display: flex;
  justify-content: flex-end;
}
.tableContainer {
  margin-top: 20px;
}

.tableHead {
  background-color: #f5f5f5;
}

.tableCellHeader {
  font-weight: bold;
}

.deleteButton {
  color: #e74c3c;
  background-color: rgba(0, 0, 255, 0.1);
  padding: 4px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-left: 20px;
}

.dialogField {
  margin-top: 5px;
  border-radius: 20px;
  margin-bottom: 15px;
}
.layout {
  display: flex;
}
.mh-container {
  width: 1000px;
  margin-left: 20px;
}

.mh-container.expanded {
  width: calc(100% - 210px);
}

.mh-container.collapsed {
  width: calc(100% - 64px);
}
