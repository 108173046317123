.Card {
  height: 70px;
  border-radius: 12px;
  padding: 1.8rem 2rem;
  width: 100%;
  background-color: white;
  border: 1px solid #e3e3e3;
  margin-top: 20px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.2);
}
