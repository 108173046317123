.onboard {
  /* max-width: 20rem; */

  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.title {
  padding: 1.2rem 1.5rem;
  margin-bottom: 0;
  font-size: 2rem;
  text-align: left;
}
.cards {
  margin-left: 0px;

  /* display: flex; */
  /* justify-content: center; */
}
.card-text {
  color: rgb(81, 80, 80);
  font-size: large;
  font-weight: bold;
}
.count {
  color: "#1BBB6B";
  margin-bottom: 20px;
  font-size: large;
  align-items: center;
  padding-bottom: 50px;
}

.max-w-10xl {
  transition: margin-left 0.3s ease;
}

.collapsed {
  margin-left: 30px;
}

.not-collapsed {
  margin-left: 8px;
}
.layout {
  display: flex;
}
.mh-container {
  width: 1000px;
  height: 1000px !important;
  margin-left: 20px;
  margin-top: 20px;
}

.mh-container.expanded {
  width: calc(100% - 200px);
  margin-right: 10px;
}

.mh-container.collapsed {
  width: calc(100% - 60px);
  margin-right: 10px;
}
