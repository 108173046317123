/* General Container Styles */
.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.header-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.header-text {
  font-weight: bold;
}

.item-details-container {
  max-width: 90%;
  margin: 0 auto;
}

/* Card Styles */
.custom-card {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-card hr {
  margin: 10px 0;
}

.custom-card .header-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.mh-container.expanded {
  width: calc(100% - 210px);
}

.mh-container.collapsed {
  width: calc(100% - 64px);
}
